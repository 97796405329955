import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  styled,
  LinearProgress,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: '#0A0B1E',
  padding: theme.spacing(6),
  borderRadius: 20,
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.1)',
  position: 'relative',
  overflow: 'hidden',
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#6B46FE',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
    background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#0A0B1E',
    border: '2px solid #6B46FE',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(107,70,254,0.2)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    background: '#6B46FE',
    borderRadius: '8px',
    padding: '4px 8px',
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiRadio-root': {
    color: 'rgba(255,255,255,0.3)',
  },
  '&.Mui-checked': {
    color: '#6B46FE',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255,255,255,0.05)',
    borderRadius: 12,
    color: 'white',
    '& fieldset': {
      borderColor: 'rgba(255,255,255,0.1)',
      transition: 'all 0.2s ease',
    },
    '&:hover fieldset': {
      borderColor: '#6B46FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6B46FE',
      borderWidth: '2px',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255,255,255,0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#6B46FE',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  padding: '14px 48px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  color: '#fff',
  '&:hover': {
    background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)',
  },
  boxShadow: '0 8px 16px rgba(107,70,254,0.2)',
}));

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 3,
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  },
}));

const questions = [
  {
    id: 'welcome',
    type: 'welcome',
    title: '👋 Hey there!',
    description: "Let's figure out what your perfect workspace looks like. It'll only take a few minutes. Ready?",
    buttonText: 'Start',
  },
  {
    id: 'privacy',
    type: 'slider',
    title: '💭 Privacy',
    question: 'When you imagine your ideal workspace, do you see a quiet, focused environment or a more open, collaborative one?',
    followUp: '🔒 On a scale of 1 to 10, how important is having your own private space?',
    min: 1,
    max: 10,
    marks: [
      { value: 1, label: 'Not important' },
      { value: 10, label: 'Very important' },
    ],
  },
  {
    id: 'networking',
    type: 'slider',
    title: '🤝 Networking',
    question: 'Do you love the buzz of meeting new people at work, or do you prefer a more close-knit, familiar vibe?',
    followUp: '📈 How would you rate the importance of networking for your team, from 1 to 10?',
    min: 1,
    max: 10,
    marks: [
      { value: 1, label: 'Not important' },
      { value: 10, label: 'Very important' },
    ],
  },
  {
    id: 'clientVisits',
    type: 'radio',
    title: '☕ Client Visits',
    question: 'Do clients or partners drop by your office often, or do most of your meetings happen virtually?',
    options: ['Mostly in-person meetings', 'Mostly virtual meetings', 'A mix of both'],
  },
  {
    id: 'growthRate',
    type: 'radio-text',
    title: '🚀 Growth Rate',
    question: 'Your team sounds exciting! How has your team size changed in the last 6 months?',
    options: ['Steady growth', 'Sudden growth spurt', 'No recent change'],
    hasOptionalText: true,
    optionalTextLabel: 'Tell us more if you\'d like!',
  },
  {
    id: 'pastWorkModel',
    type: 'radio',
    title: '🕒 Past Work Model',
    question: 'Thinking back to three months ago, how did your team usually work?',
    options: ['Remote', 'Hybrid', 'In-office'],
  },
  {
    id: 'currentWorkModel',
    type: 'radio',
    title: '🔄 Current Work Model',
    question: 'And how about now? Has the way your team works changed recently?',
    options: ['Still the same', 'Moved to hybrid', 'Moved to remote', 'Moved back to the office'],
  },
  {
    id: 'fundingStatus',
    type: 'radio',
    title: '💡 Funding Status',
    question: 'Every company\'s journey is unique! How would you describe where your company is right now?',
    options: ['Bootstrapped', 'Recently funded', 'Growth mode'],
  },
  {
    id: 'officeType',
    type: 'radio',
    title: '📍 Office Type',
    question: 'Is this space going to be your main hub or more of a branch location to support your growing presence?',
    options: ['HQ/Main hub', 'Branch location'],
  }
  // {
  //   id: 'thankYou',
  //   type: 'thank-you',
  //   title: '🎉 Thank You!',
  //   description: "Thanks for sharing! We've got a much better idea of your workspace needs now. We'll be in touch soon with some great options!",
  //   buttonText: 'Finish',
  // },
];

function Questionnaire({ setAnswers }) {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState({
    reportType: 'advisorai'  // Set default report type here
  });
  const [otherText, setOtherText] = useState('');

  const handleSliderChange = (value) => {
    setResponses({
      ...responses,
      [questions[currentQuestion].id]: value,
    });
  };

  const handleRadioChange = (value) => {
    setResponses({
      ...responses,
      [questions[currentQuestion].id]: value,
    });
    if (value !== 'Other (please specify)') {
      setOtherText('');
    }
  };

  const handleNext = () => {
    // Update responses with reportType if not already set
    const updatedResponses = {
      ...responses,
      reportType: responses.reportType || 'advisorai'
    };

    if (currentQuestion === questions.length - 1) {
      setAnswers(updatedResponses);
      navigate('/company-details'); // Update navigation path
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const isNextDisabled = () => {
    const currentValue = responses[currentQuestionData.id];
    
    switch (currentQuestionData.type) {
      case 'radio':
      case 'radio-text':
      case 'dropdown':
        return !currentValue;
      case 'slider':
        return currentValue === undefined || currentValue === null;
      case 'text':
        return !currentValue || currentValue.trim() === '';
      default:
        return false;
    }
  };

  const currentQuestionData = questions[currentQuestion];
  const progress = ((currentQuestion) / (questions.length)) * 100;

  const renderQuestion = () => {
    switch (currentQuestionData.type) {
      case 'welcome':
      case 'thank-you':
        return (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h3" sx={{
              fontSize: '3rem',
              mb: 2,
            }}>
              {currentQuestionData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 6, color: 'text.secondary', fontSize: '1.1rem' }}>
              {currentQuestionData.description}
            </Typography>
          </Box>
        );

      case 'slider':
        return (
          <Box sx={{ py: 4 }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
              {currentQuestionData.question}
            </Typography>
            <Typography variant="body1" sx={{
              fontSize: '1rem',
              mb: 2,
              background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            >
              {currentQuestionData.followUp}
            </Typography>
            <Box sx={{ px: 3, py: 4 }}>
              <StyledSlider
                value={responses[currentQuestionData.id] || 5}
                min={currentQuestionData.min}
                max={currentQuestionData.max}
                onChange={(_, value) => handleSliderChange(value)}
                valueLabelDisplay="on"
                marks
              />
            </Box>
          </Box>
        );

      case 'radio':
      case 'radio-text':
        return (
          <Box sx={{ py: 4 }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
              {currentQuestionData.question}
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={responses[currentQuestionData.id] || ''}
                onChange={(e) => handleRadioChange(e.target.value)}
              >
                <Box sx={{ display: 'grid', gap: 2 }}>
                  {currentQuestionData.options.map((option) => (
                    <Paper
                      key={option}
                      sx={{
                        p: 2,
                        border: '1px solid',
                        borderColor: responses[currentQuestionData.id] === option ? '#6B46FE' : 'rgba(255,255,255,0.1)',
                        borderRadius: 2,
                        background: 'rgba(255,255,255,0.05)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          borderColor: '#6B46FE',
                          backgroundColor: 'rgba(107,70,254,0.1)',
                        },
                      }}
                    >
                      <FormControlLabel
                        value={option}
                        control={<StyledRadio />}
                        label={option}
                        sx={{ m: 0, width: '100%' }}
                      />
                    </Paper>
                  ))}
                </Box>
              </RadioGroup>
              {currentQuestionData.type === 'radio-text' &&
                responses[currentQuestionData.id] === 'Other (please specify)' && (
                  <StyledTextField
                    fullWidth
                    placeholder="Please specify your growth rate"
                    value={otherText}
                    onChange={(e) => setOtherText(e.target.value)}
                  />
                )}
            </FormControl>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{
        mt: { xs: 4, sm: 8 },
        mb: 4,
        px: { xs: 2, sm: 0 },
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '16px',
          zIndex: -1
        }
      }}>
        <StyledProgressBar variant="determinate" value={progress} sx={{ mb: 4 }} />

        <StyledPaper elevation={0}>
          {renderQuestion()}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 4
          }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
              sx={{
                visibility: currentQuestion === 0 ? 'hidden' : 'visible',
                color: '#0077B5',
                '&:hover': {
                  backgroundColor: 'rgba(0, 119, 181, 0.08)',
                }
              }}
            >
              Back
            </Button>
            <StyledButton
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
              disabled={isNextDisabled()}
              sx={{
                background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                color: 'white',
                '&:hover': {
                  background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)'
                },
                '&.Mui-disabled': {
                  background: 'rgba(255, 255, 255, 0.12)',
                  color: 'rgba(255, 255, 255, 0.3)'
                }
              }}
            >
              {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
            </StyledButton>
          </Box>

          {/* Hidden Typography for Report Type */}
          <Typography
            sx={{
              display: 'none',  // Completely hidden from UI
              visibility: 'hidden',
              position: 'absolute',
              width: 0,
              height: 0,
              overflow: 'hidden'
            }}
          >
            {responses.reportType}
          </Typography>
        </StyledPaper>
      </Box>
    </Container>
  );
}

export default Questionnaire;
