import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import AdvisorAIGetStarted from "./components/AdvisorAIGetStarted";
import CompanyDetails from "./components/CompanyDetails";
import Questionnaire from "./components/Questionnaire";
import Report from "./components/Report";
import Landing from "./components/Landing";
import Negotiatorgetstart from "./components/Negotiator.Ai/Negotiatorgetstart";
import NegotiatorQuestions from "./components/Negotiator.Ai/NegotiatorQuestions";
import theme from "./theme";
import ThankYou from "./components/ThankyouScreen/Thankyou";

function App() {
  const [advisorStarted, setAdvisorStarted] = useState(false);
  const [negotiatorStarted, setNegotiatorStarted] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [reportResponse, setReportResponse] = useState(null);
  const navigate = useNavigate();

  // console.log(answers, "answers");

  useEffect(() => {
    if (advisorStarted) {
      navigate('/advisor-ai/questionnaire');
      // window.open("/advisor-ai/questionnaire", "_blank"); // Open Questionnaire in a new tab
    }
  }, [advisorStarted]);

  useEffect(() => {
    if (negotiatorStarted) {
      navigate('/negotiator-ai/questionnaire');
      // window.open("/negotiator-ai/questionnaire", "_blank"); // Open NegotiatorQuestions in a new tab
    }
  }, [negotiatorStarted]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/advisor-ai"
          element={<AdvisorAIGetStarted onStart={() => setAdvisorStarted(true)} />}
        />
        <Route
          path="/advisor-ai/questionnaire"
          element={<Questionnaire setAnswers={setAnswers} />}
        />
        <Route
          path="/company-details"
          element={
              <CompanyDetails
                answers={answers}
                setCompanyData={setCompanyData}
                setReportResponse={setReportResponse}
                negotiatorStarted={negotiatorStarted}
                advisorStarted={advisorStarted}
              />
          }
        />
        <Route
          path="/negotiator-ai"
          element={<Negotiatorgetstart onStart={() => setNegotiatorStarted(true)} />}
        />
        <Route
          path="/negotiator-ai/questionnaire"
          element={<NegotiatorQuestions setAnswers={setAnswers} />}
        />
        <Route path="/benchmark-ai" element={<Landing />} />
        <Route path="/advisorai-report/:documentId" element={<Report />} />
        <Route path="/negotiatorai-report/:documentId" element={<Report />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
