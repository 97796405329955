import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  MenuItem,
  Divider,
  styled,
  Modal,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getAdvisoraiReport, getNegotiatoraiReport, storeAIRecommendation } from '../services/reportService';

const TIMING_OPTIONS = [
  { value: 'immediate', label: 'Immediate' },
  { value: 'next_month', label: 'In the next month' },
  { value: 'next_quarter', label: 'Next quarter' },
];

const CITY_OPTIONS = [
  { value: 'Chennai', label: 'Chennai' },
  { value: 'Bengaluru', label: 'Bengaluru' },
];

const COMPANY_TYPE_OPTIONS = [
  { value: 'Startup', label: 'Startup' },
  { value: 'SMB', label: 'SMB' },
  { value: 'Enterprise', label: 'Enterprise' },
];

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  padding: '14px 48px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
  color: '#ffffff',
  boxShadow: '0 4px 20px rgba(107, 70, 254, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #5835E5, #E53D7A)',
    boxShadow: '0 6px 25px rgba(107, 70, 254, 0.4)',
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: '#0A0B1E',
  padding: theme.spacing(2),
  borderRadius: 20,
  boxShadow: '0 8px 32px rgba(107, 70, 254, 0.15)',
  position: 'relative',
  overflow: 'hidden',
  color: '#ffffff',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6px',
    background: 'linear-gradient(90deg, #6B46FE, #FF4D8D)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginBottom: theme.spacing(1),
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: 12,
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: '#6B46FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6B46FE',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '1rem',
    fontWeight: 500,
    '&.Mui-focused': {
      color: '#6B46FE',
    },
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #0A0B1E inset',
      '-webkit-text-fill-color': '#ffffff',
    },
  },
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiMenuItem-root': {
    color: '#ffffff',
  },
}));

const ProgressModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ProgressContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#0A0B1E',
  boxShadow: '0 8px 32px rgba(107, 70, 254, 0.2)',
  padding: theme.spacing(4),
  borderRadius: 16,
  outline: 'none',
  maxWidth: 400,
  width: '90%',
  position: 'relative',
  textAlign: 'center',
  color: '#ffffff',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #6B46FE, #FF4D8D)',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  }
}));

const PERSONAL_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'mail.com',
];

const PROGRESS_MESSAGES = [
  'Spinning AI magic — stand by for insights! ✨',
];

export default function CompanyDetails({ answers, setCompanyData, setReportResponse, negotiatorStarted, AdvisorStarted }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    email: '',
    phone: '',
    timing: '',
    city: '',
    teamSize: '',
    companyType: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [hasCompletedCycle, setHasCompletedCycle] = useState(false);

  useEffect(() => {
    let interval;
    if (isLoading && !hasCompletedCycle) {
      interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex === PROGRESS_MESSAGES.length) {
            setHasCompletedCycle(true);
            return prevIndex;
          }
          return nextIndex;
        });
      }, 4000);
    }
    return () => clearInterval(interval);
  }, [isLoading, hasCompletedCycle]);

  const validateField = (name, value) => {
    switch (name) {
      case 'fullName':
        return value.trim() ? '' : 'Full name is required';

      case 'companyName':
        return value.trim() ? '' : 'Company name is required';

      case 'email': {
        if (!value) return 'Email is required';
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return 'Invalid email address';
        }
        const domain = value.split('@')[1]?.toLowerCase();
        if (PERSONAL_EMAIL_DOMAINS.includes(domain)) {
          return 'Please use your work email';
        }
        return '';
      }

      case 'phone': {
        if (!value) return 'Phone number is required';
        if (!/^[6-9]\d{9}$/.test(value)) {
          return 'Please enter a valid Indian mobile number';
        }
        return '';
      }

      case 'timing':
        return value ? '' : 'Please select when you\'re looking to take an office';

      case 'city':
        return value ? '' : 'Please select a city';

      case 'teamSize': {
        if (!value) return 'Team size is required';
        if (value < 1) return 'Team size must be at least 1';
        if (!/^\d+$/.test(value)) return 'Team size must be a whole number';
        return '';
      }

      case 'companyType':
        return value ? '' : 'Please select your company type';

      default:
        return '';
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) newErrors[field] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formattedData = {
      ...formData,
      phone: formData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '+91 $1-$2-$3'),
    };

    setCompanyData(formattedData);
    setIsLoading(true);
    try {
      let data = {
        formattedData: formattedData,
        answers
      }
      
      const response = await storeAIRecommendation(data);
      console.log("Store AI Recommendation Response", response);
      
      if (response && response.success) {
        navigate('/thank-you');
      } else {
        console.error('Store AI Recommendation failed', response);
        // Optionally show an error message to the user
      }

    } catch (error) {
      console.error('Error in handleSubmit:', error);
      // Optionally show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const filledFields = Object.values(formData).filter(Boolean).length;
  const progress = (filledFields / Object.keys(formData).length) * 100;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        background: '#0A0B1E',
      }}
    >
      <Container maxWidth="md" sx={{ py: 4 }}>
        <StyledPaper elevation={3}>
          <Box sx={{ p: 1 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                textAlign: 'center',
                fontWeight: 600,
                mb: 1,
                color: '#ffffff',
              }}
            >
              🎉 Thank you
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                textAlign: 'center',
                mb: 4,
                color: '#ffffff',
              }}
            >
              Our AI Advisor is working on your information. Share with us your details to send you the report by email.
            </Typography>

            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                gap: 2, 
                maxWidth: 900, 
                margin: '0 auto' 
              }}>
                <StyledTextField
                  label="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  // margin="normal"
                  required
                />

                <StyledTextField
                  label="Company Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                  // margin="normal"
                  required
                />

                <StyledTextField
                  label="Work Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  // margin="normal"
                  required
                />

                <StyledTextField
                  label="Mobile Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  // margin="normal"
                  required
                  inputProps={{
                    maxLength: 10,
                  }}
                />

                <StyledTextField
                  select
                  label="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                  // margin="normal"
                  required
                >
                  {CITY_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>

                <StyledTextField
                  label="Team Size"
                  name="teamSize"
                  type="number"
                  value={formData.teamSize}
                  onChange={handleChange}
                  error={!!errors.teamSize}
                  helperText={errors.teamSize}
                  // margin="normal"
                  required
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                />

                <StyledTextField
                  select
                  label="Company Type"
                  name="companyType"
                  value={formData.companyType}
                  onChange={handleChange}
                  error={!!errors.companyType}
                  helperText={errors.companyType}
                  // margin="normal"
                  required
                >
                  {COMPANY_TYPE_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>

                <StyledTextField
                  select
                  label="When are you looking to take an office?"
                  name="timing"
                  value={formData.timing}
                  onChange={handleChange}
                  error={!!errors.timing}
                  helperText={errors.timing}
                  // margin="normal"
                  required
                >
                  {TIMING_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Box>
            </form>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Button
              onClick={handleBack}
              startIcon={<ArrowBackIcon />}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              BACK
            </Button>
            <StyledButton
              onClick={handleSubmit}
              endIcon={<ArrowForwardIcon />}
            >
              Finish
            </StyledButton>
          </Box>
        </StyledPaper>

        <ProgressModal
          open={isLoading}
          aria-labelledby="progress-modal-title"
          aria-describedby="progress-modal-description"
        >
          <ProgressContent>
            <CircularProgress
              size={60}
              thickness={4}
              sx={{ mb: 3 }}
            />
            <Typography variant="h6" sx={{ mb: 2 }}>
              {PROGRESS_MESSAGES[currentMessageIndex]}
            </Typography>
            
          </ProgressContent>
        </ProgressModal>
      </Container>
    </Box>
  );
}
