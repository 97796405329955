async function getAdvisoraiReport(reportRequest) {
  //Call the backend API to generate the report
  //https://us-central1-gofloaters-property-manager.cloudfunctions.net/recommendOfficeHttp
  const functionUrl = 'https://us-central1-gofloaters-property-manager.cloudfunctions.net/recommendOfficeHttp';
  const response = await fetch(functionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify(reportRequest),
  });
  const data = await response.json();
  return data;
}

async function getNegotiatoraiReport(reportRequest) {

  //Call the backend API to generate the report
  //https://us-central1-gofloaters-property-manager.cloudfunctions.net/recommendOfficeHttp
  const functionUrl = 'https://us-central1-gofloaters-property-manager.cloudfunctions.net/analyzePropertyHttp';
  const response = await fetch(functionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    mode: 'cors',
    credentials: 'same-origin',
    body: JSON.stringify(reportRequest),
  });
  const data = await response.json();
  return data;
}

async function storeAIRecommendation(data) {
  let FUNCTIONS_BASE_URL = "";
  switch (process.env.REACT_APP_ENV) {
    case 'DEV':
      FUNCTIONS_BASE_URL = process.env.REACT_APP_DEV_FUNCTIONS_BASE_URL
      break;
    case 'LOCAL':
      FUNCTIONS_BASE_URL = process.env.REACT_APP_LOCAL_FUNCTIONS_BASE_URL
      break;
    case 'PROD':
      FUNCTIONS_BASE_URL = process.env.REACT_APP_PROD_FUNCTIONS_BASE_URL
      break;
    default:
      break;
  }

console.log(FUNCTIONS_BASE_URL)
  const functionUrl = `${FUNCTIONS_BASE_URL}/storeAIRecommendation`;
  
  const response = await fetch(functionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    
  });
  console.log(response)
  const responseData = await response.json();
  return responseData;
}

async function getReportData(documentId) {
  try {
    let FUNCTIONS_BASE_URL = "";
    switch (process.env.REACT_APP_ENV) {
      case 'DEV':
        FUNCTIONS_BASE_URL = process.env.REACT_APP_DEV_FUNCTIONS_BASE_URL
        break;
      case 'LOCAL':
        FUNCTIONS_BASE_URL = process.env.REACT_APP_LOCAL_FUNCTIONS_BASE_URL
        break;
      case 'PROD':
        FUNCTIONS_BASE_URL = process.env.REACT_APP_PROD_FUNCTIONS_BASE_URL
        break;
      default:
        break;
    }
    const functionUrl = `${FUNCTIONS_BASE_URL}/getReportData`;

    const response = await fetch(`${functionUrl}?documentId=${documentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data, "data")

    // More detailed validation
    if (!data) {
      throw new Error('No data received from server');
    }

    // Extract reportType from the document data
    const reportType = data.reportType || data.data?.reportType;
    const apiResponse = data.apiResponse || data.data?.apiResponse;
    const questionaire = data.questionaire || data.data?.questionaire;
    const companyData = data.companyData || data.data?.companyData;

    if (!reportType) {
      console.warn('Report type not found in response');
    }

    return {
      success: true,
      data: {
        reportType: reportType,
        response: apiResponse?.data || data || {},
        requirement: questionaire,
        companyData: companyData
      }
    };
  } catch (error) {
    console.error('Error in getReportData:', error);
    return {
      success: false,
      error: error.message || 'Failed to fetch report data'
    };
  }
}

export { getAdvisoraiReport, getNegotiatoraiReport, storeAIRecommendation, getReportData };