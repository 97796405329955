import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThankYouImage from '../../assets/images/tick.png';

const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    color: 'white',
                    p: 4
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        mb: 4,
                        background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                >
                    Thank You!
                </Typography>
                <Box
                    component="img"
                    src={ThankYouImage}
                    alt="Thank You Illustration"
                    sx={{
                        maxWidth: '170px',
                        width: '190%',
                        mb: 4,
                        filter: 'brightness(1.2) saturate(1.5)'
                    }}
                />



                <Typography variant="h5" sx={{ mb: 1 }}>
                    GoFloaters AI Advisor is preparing your report.
                </Typography>
                <Typography variant="h5" sx={{ mb: 3 }}>
                Your report will be in your inbox in a few minutes. 🚀
                </Typography>

                <Button
                    variant="contained"
                    onClick={() => navigate('/')}
                    sx={{
                        background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                        color: 'white',
                        px: 4,
                        py: 2,
                        borderRadius: 4,
                        '&:hover': {
                            background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)',
                        }
                    }}
                >
                    Return  Home
                </Button>
            </Box>
        </Container>
    );
};

export default ThankYou;
