import React, { useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Stack,
  AppBar,
  Toolbar,
  Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import customImage from '../assets/images/customimage.avif';
import AdvisorAI from '../assets/images/advisorai-product.jpeg';
import BenchmarkAI from '../assets/images/benchmarkai-product.jpg';
import NegotiatorAI from '../assets/images/negotiatorai-product.jpg';
import goFloatersLogo from '../assets/images/gofloaters-logo.png';
import { Business, TrendingUp, LocationCity } from '@mui/icons-material';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const tools = [
  {
    title: 'Advisor.AI',
    description: 'Get AI-powered recommendations for your ideal office space based on your unique business needs',
    image: AdvisorAI,
    actionText: "Let's Go",
    comingSoonText: "Coming Soon",
    path: '/advisor-ai',
    isActive: process.env.REACT_APP_ADVISOR_AI_ACTIVE === "TRUE"
  },
  {
    title: 'Benchmark.AI',
    description: 'Compare office spaces and analyze key metrics to make data-driven decisions',
    image: BenchmarkAI,
    actionText: 'Compare Now',
    comingSoonText: "Coming Soon",
    path: '/benchmark-ai',
    isActive: process.env.REACT_APP_BENCHMARK_AI_ACTIVE === "TRUE"
  },
  {
    title: 'Negotiator.AI',
    description: 'Let our AI help you negotiate rent and rental agreement clauses for the best deal',
    image: NegotiatorAI,
    actionText: 'Start Negotiating',
    comingSoonText: "Coming Soon",
    path: '/negotiator-ai',
    isActive: process.env.REACT_APP_NEGOTIATOR_AI_ACTIVE === "TRUE"
  }
];

const Header = ({ onNavigate }) => {
  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        background: 'transparent', 
        boxShadow: 'none',
        borderBottom: '1px solid rgba(255,255,255,0.1)',
        backdropFilter: 'blur(10px)'
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
          <Box 
            component="img"
            src={goFloatersLogo}
            alt="GoFloaters Logo"
            sx={{ height: 65 }}
          />
          
          <Box sx={{ display: 'flex', gap: 3 }}>
            {tools.map((tool) => 
              tool.isActive ? (
                <Button
                  key={tool.title}
                  onClick={() => onNavigate(tool.path)}
                  sx={{ 
                    color: 'text.primary',
                    '&:hover': { 
                      color: 'text.primary',
                      background: 'rgba(107,70,254,0.1)'
                    },
                    borderRadius: '8px',
                    px: 2
                  }}
                >
                  {tool.title}
                </Button>
              ) : null
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const agentsSectionRef = useRef(null);

  const particlesInit = async (engine) => {
    await loadSlim(engine);
  };

  const particlesConfig = {
    fpsLimit: 120,
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: ["#6B46FE", "#FF4D8D", "#8B5CF6"]
      },
      links: {
        enable: true,
        distance: 150,
        color: "#6B46FE",
        opacity: 0.2,
        width: 1
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 0.5,
        random: {
          enable: true,
          minimumValue: 0.1
        },
        animation: {
          enable: true,
          speed: 1,
          minimumValue: 0.1,
          sync: false
        }
      },
      size: {
        value: 3,
        random: {
          enable: true,
          minimumValue: 1
        }
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        random: false,
        straight: false,
        outModes: {
          default: "bounce"
        },
        attract: {
          enable: true,
          rotateX: 600,
          rotateY: 1200
        }
      },
    },
    interactivity: {
      detectsOn: "canvas",
      events: {
        onHover: {
          enable: true,
          mode: "grab"
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 150,
          links: {
            opacity: 0.5
          }
        }
      }
    },
    background: {
      color: "transparent"
    },
    detectRetina: true
  };

  const handleNavigate = (path) => {
    window.open(window.location.origin + path, '_blank');
  };

  const scrollToAgents = () => {
    agentsSectionRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', position: 'relative' }}>
      <Header onNavigate={handleNavigate} />
      <Particles
            id="tsparticles"
            init={particlesInit}
            options={particlesConfig}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1
            }}
          />
      {/* Add padding to account for fixed header */}
      <Box sx={{ pt: 8 }}>
        {/* Hero Section */}
        <Box
          sx={{
            position: 'relative',
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          
          
          <Container 
            maxWidth="lg" 
            sx={{ 
              position: 'relative',
              zIndex: 2
            }}
          >
            <Stack spacing={4} alignItems="center">
              <Typography variant="h1" 
                sx={{ 
                  fontSize: { xs: '2.5rem', md: '4rem' },
                  textAlign: 'center',
                  maxWidth: '800px',
                  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 2
                }}
              >
                Your Perfect Office Space—Smarter, Faster, with AI!
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: 'text.secondary',
                  maxWidth: '600px',
                  textAlign: 'center',
                  mb: 4
                }}
              >
                The world's only AI advisor platform that helps founders make smarter, faster, and more cost-effective office space decisions.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={scrollToAgents}
                sx={{
                  px: 4,
                  py: 2,
                  fontSize: '1.1rem',
                  borderRadius: '30px',
                  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)',
                  },
                  boxShadow: '0 8px 16px rgba(107,70,254,0.2)',
                }}
              >
                GET STARTED
              </Button>
            </Stack>
          </Container>
        </Box>

        {/* Target Audience Section */}
        <Box sx={{ 
          py: 8,
          // background: 'linear-gradient(180deg, #0A0B1E 0%, rgba(10, 11, 30, 0.95) 100%)',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: 'linear-gradient(90deg, transparent, #6B46FE, #FF4D8D, transparent)',
          }
        }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 600,
                mb: 2,
                background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Who is this for?
            </Typography>
            <Typography
              variant="h6"
              align="center"
              sx={{
                mb: 6,
                color: 'rgba(255, 255, 255, 0.7)',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Our AI Advisors are designed to help decision-makers navigate the complexities of office space selection
            </Typography>

            <Grid container spacing={4}>
              {/* Card 1 - Founders */}
              <Grid item xs={12} md={4}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <Business sx={{ fontSize: 30, color: '#FF4D8D' }} />
                      <Typography variant="h5" sx={{ color: '#ffffff', fontWeight: 600, textAlign: 'left', m: 0 }}>
                        First-time Founders
                      </Typography>
                    </Box>
                    
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Making your first office space decision? Advisor.AI helps you understand the key factors and make an informed choice for your startup's growth.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Card 2 - Business Owners */}
              <Grid item xs={12} md={4}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <LocationCity sx={{ fontSize: 30, color: '#FF4D8D' }} />
                      <Typography variant="h5" sx={{ color: '#ffffff', fontWeight: 600, textAlign: 'left', m: 0 }}>
                        Business Owners
                      </Typography>
                    </Box>
                    
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Exploring flexible workspace solutions? Get expert insights on coworking and managed offices to find the perfect fit for your business.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Card 3 - Leaders */}
              <Grid item xs={12} md={4}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <TrendingUp sx={{ fontSize: 30, color: '#FF4D8D' }} />
                      <Typography variant="h5" sx={{ color: '#ffffff', fontWeight: 600, textAlign: 'left', m: 0 }}>
                        Market Explorers
                      </Typography>
                    </Box>
                    
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Expanding to a new city? Get up-to-date market insights, trends, and recommendations tailored to your specific location and requirements.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Tools Section */}
        <Box ref={agentsSectionRef}>
          <Container maxWidth="lg" sx={{ pt: 15, pb: 8 }}>
          <Typography
              variant="h2"
              align="center"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 600,
                mb: 6,
                background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Our AI Advisors
            </Typography>
            <Grid container spacing={4}>
              {tools.map((tool, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 3,
                      borderRadius: '20px',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                        border: '1px solid rgba(107, 70, 254, 0.3)',
                      }
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <img
                        src={tool.image}
                        alt={tool.title}
                        style={{
                          width: '100%',
                          height: 200,
                          objectFit: 'cover',
                          borderRadius: '12px',
                          marginBottom: '16px'
                        }}
                      />
                      <Typography 
                        variant="h5" 
                        sx={{
                          mb: 1,
                          background: 'linear-gradient(45deg, #6B46FE 30%, #A891FE 90%)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent'
                        }}
                      >
                        {tool.title}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2,color: 'rgba(255, 255, 255, 0.7)' }}>
                        {tool.description}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => handleNavigate(tool.path)}
                      disabled={!tool.isActive}
                      sx={{
                        py: 1.5,
                        background: 'linear-gradient(45deg, #6B46FE 30%, #A891FE 90%)',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #5B36EE 30%, #9881EE 90%)'
                        }
                      }}
                    >
                      {tool.isActive ? tool.actionText : (tool.comingSoonText || "Coming Soon")}
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Expertise Section */}
        <Box sx={{ 
          py: 12,
          // background: 'linear-gradient(180deg, rgba(10, 11, 30, 0.95) 0%, #0A0B1E 100%)',
          position: 'relative'
        }}>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 600,
                mb: 2,
                background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Built on Years of Expertise
            </Typography>
            <Typography
              variant="h6"
              align="center"
              sx={{
                mb: 8,
                color: 'rgba(255, 255, 255, 0.7)',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Our AI Advisors are powered by real-world experience and comprehensive market data
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <Typography variant="h2" sx={{ 
                        background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}>
                        8+
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontWeight: 600, textAlign: 'left' }}>
                      Years of Experience
                    </Typography>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Helping hundreds of startups and businesses make informed office space decisions
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <Typography variant="h2" sx={{ 
                        background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}>
                        2000+
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontWeight: 600, textAlign: 'left' }}>
                      Spaces Analyzed
                    </Typography>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Comprehensive data on coworking and managed offices across the country
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <Typography variant="h2" sx={{ 
                        background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}>
                        100%
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontWeight: 600, textAlign: 'left' }}>
                      Expert Research
                    </Typography>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Primary market research on evolving workplace needs and models
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Card sx={{
                  height: '100%',
                  background: 'rgba(255, 255, 255, 0.03)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: '20px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    boxShadow: '0 20px 40px rgba(107, 70, 254, 0.2)',
                    border: '1px solid rgba(107, 70, 254, 0.3)',
                  }
                }}>
                  <CardContent sx={{ p: 4 }}>
                    <Box
                      className="icon-wrapper"
                      sx={{
                        width: 'auto',
                        height: 60,
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <Typography variant="h2" sx={{ 
                        background: 'linear-gradient(45deg, #6B46FE, #FF4D8D)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}>
                        50+
                      </Typography>
                    </Box>
                    <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontWeight: 600, textAlign: 'left' }}>
                      Industry Reports
                    </Typography>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      Insights from credible research firms and market analysis
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Footer */}
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: 'rgba(107, 70, 254, 0.03)',
            borderTop: '1px solid rgba(107, 70, 254, 0.1)',
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: 'rgba(255, 255, 255, 0.7)' }}
              >
                &copy; {new Date().getFullYear()} GoFloaters. All rights reserved.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                }}
              >
                <Link
                  href="https://gofloaters.com/privacy"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    textDecoration: 'none',
                    '&:hover': {
                      color: '#fff',
                      textDecoration: 'none',
                    },
                  }}
                >
                  Privacy Policy
                </Link>
                <Link
                  href="https://gofloaters.com/terms"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    textDecoration: 'none',
                    '&:hover': {
                      color: '#fff',
                      textDecoration: 'none',
                    },
                  }}
                >
                  Terms of Service
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Landing;
