import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';

export default function Negotiatorgetstart({ onStart }) {
  const handleGetStarted = () => {
    onStart();
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        bgcolor: 'background.default',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '800px',
          height: '800px',
          background: 'radial-gradient(circle, rgba(107,70,254,0.15) 0%, rgba(255,77,141,0.15) 50%, rgba(0,0,0,0) 70%)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
         <Paper
            elevation={3}
            sx={{
              p: { xs: 4, md: 6 },
              width: '100%',
              maxWidth: 800,
              borderRadius: 4,
              background: 'rgba(21, 22, 41, 0.7)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(255,255,255,0.1)',
            }}
          >
            <Stack spacing={4} alignItems="center">
            <Typography
                component="h1"
                sx={{
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  textAlign: 'center',
                }}
              >
               Welcome Negotiator.AI
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                  maxWidth: 600,
                  textAlign: 'center',
                  lineHeight: 1.6,
                }}
              >
                Let GoFloaters' AI-powered advisor help you make the perfect office decision for your business needs.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={handleGetStarted}
                sx={{
                  px: 6,
                  py: 2,
                  fontSize: '1.1rem',
                  borderRadius: '30px',
                  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)',
                  },
                  boxShadow: '0 8px 16px rgba(107,70,254,0.2)',
                }}
              >
                Let's Begin
              </Button>
            </Stack>
            <Box
              sx={{
                mt: 8,
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
                gap: 4,
              }}
            >
              {[
                {
                  title: 'Smart Analysis',
                  description: 'Get AI-powered insights based on your specific needs',
                },
                {
                  title: 'Quick Results',
                  description: 'Receive detailed recommendations in minutes',
                },
                {
                  title: 'Expert Guidance',
                  description: 'Make informed decisions with data-driven advice',
                },
              ].map((feature, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    background: 'rgba(107,70,254,0.1)',
                    border: '1px solid rgba(107,70,254,0.2)',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 600,
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}
