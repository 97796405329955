import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Slider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  styled,
  LinearProgress,
  Select,
  MenuItem,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: '#0A0B1E',
  padding: theme.spacing(6),
  borderRadius: 20,
  border: '1px solid rgba(255,255,255,0.1)',
  position: 'relative',
  overflow: 'hidden',
  color: '#ffffff',
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#6B46FE',
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
    background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#0A0B1E',
    border: '2px solid #6B46FE',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(107,70,254,0.2)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
    borderRadius: '8px',
    padding: '4px 8px',
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.MuiRadio-root': {
    color: 'rgba(255,255,255,0.5)',
  },
  '&.Mui-checked': {
    color: '#6B46FE',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    color: '#fff',
    borderRadius: 12,
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.1)',
      transition: 'all 0.2s ease',
    },
    '&:hover fieldset': {
      borderColor: '#6B46FE',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6B46FE',
      borderWidth: '2px',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 30,
  padding: '14px 48px',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  color: '#ffffff',
  boxShadow: '0 4px 20px rgba(107, 70, 254, 0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #5835E5, #E53D7A)',
    boxShadow: '0 6px 25px rgba(107, 70, 254, 0.4)',
  }
}));

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 3,
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiLinearProgress-bar': {
    background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  },
}));

const questions = [
  {
    id: 'welcome',
    type: 'welcome',
    title: '👋 Hey there!',
    description: "Let's help you negotiate your ideal workspace like a pro. Ready?",
    buttonText: 'Start',
  },
  {
    id: 'city',
    type: 'dropdown',
    title: 'In which city are you taking up a new office?',
    options: ['Chennai', 'Bengaluru'],
  },
  {
    id: 'propertyType',
    type: 'dropdown',
    title: 'Which kind of property are you looking at?',
    options: ['Coworking', 'Managed', 'Conventional Office'],
  },
  {
    id: 'billableSeats',
    type: 'slider',
    title: 'What is the size of office you are taking up?',
    min: 1,
    max: 200,
  },
  {
    id: 'rentPerMonth',
    type: 'text',
    title: 'What is the rent per seat per month that has been proposed?',
  },
  {
    id: 'agreementTerm',
    type: 'radio',
    title: 'What is the duration of the agreement proposed?',
    options: [3, 6, 12, 24],
  },
  {
    id: 'lockinPeriod',
    type: 'radio',
    title: 'What is the lock-in period proposed?',
    options: [3, 6, 12, 24],
  },
  {
    id: 'noticePeriod',
    type: 'radio',
    title: 'What is the notice period proposed?',
    options: [1, 3, 6],
  },
  {
    id: 'securityDeposit',
    type: 'radio',
    title: 'How many months of security deposit are you expected to pay?',
    options: [1, 3, 4, 6],
  },

  {
    id: 'rentalEscalation',
    type: 'radio',
    title: 'What is the annual rental escalation percentage?',
    options: [5, 6, 7, 10],
  },
];

const NegotiatorQuestions = ({ setAnswers }) => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState({
    reportType: 'negotiatorai',
    propertyName: 'awfis',
  });
  const [otherText, setOtherText] = useState('');

  const handleSliderChange = (value) => {
    setResponses({
      ...responses,
      [questions[currentQuestion].id]: value,
    });
  };

  const handleRadioChange = (value) => {
    const numericValue = Number(value);
    setResponses({
      ...responses,
      [questions[currentQuestion].id]: numericValue,
    });

    if (value !== 'Other (please specify)') {
      setOtherText('');
    }
  };

  const handleDropdownChange = (value) => {
    setResponses({
      ...responses,
      [questions[currentQuestion].id]: value,
    });
  };

  const handleTextChange = (value) => {
    // Convert to number if the field is numeric
    const numericFields = ['rentPerMonth'];
    const fieldId = questions[currentQuestion].id;

    setResponses({
      ...responses,
      [fieldId]: numericFields.includes(fieldId) ? Number(value) : value,
    });
  };

  const handleNext = () => {
    const updatedResponses = {
      ...responses,
      reportType: responses.reportType || 'negotiatorai'
    };

    if (currentQuestion === questions.length - 1) {
      setAnswers(updatedResponses);
      navigate('/company-details');
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const isNextDisabled = () => {
    const currentValue = responses[currentQuestionData.id];
    
    switch (currentQuestionData.type) {
      case 'radio':
      case 'radio-text':
      case 'dropdown':
        return !currentValue;
      case 'slider':
        return currentValue === undefined || currentValue === null;
      case 'text':
        // Handle both string and number values
        if (typeof currentValue === 'number') {
          return currentValue === undefined || currentValue === null;
        }
        return !currentValue || (typeof currentValue === 'string' && currentValue.trim() === '');
      default:
        return false;
    }
  };

  const currentQuestionData = questions[currentQuestion];
  const progress = ((currentQuestion) / (questions.length)) * 100;

  const renderQuestion = () => {
    switch (currentQuestionData.type) {
      case 'welcome':
      case 'thank-you':
        return (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h3" sx={{
              fontSize: '3rem',
              mb: 2,
              background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              {currentQuestionData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 6, color: 'text.secondary', fontSize: '1.1rem' }}>
              {currentQuestionData.description}
            </Typography>
          </Box>
        );

      case 'slider':
        return (
          <Box sx={{ py: 4 }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
              {currentQuestionData.question}
            </Typography>
            <Typography variant="body1" sx={{ mt: 3, mb: 2 }}>
              {currentQuestionData.followUp}
            </Typography>
            <Box sx={{ px: 3, py: 4 }}>
              <StyledSlider
                value={responses[currentQuestionData.id] || 5}
                min={currentQuestionData.min}
                max={currentQuestionData.max}
                onChange={(_, value) => handleSliderChange(value)}
                valueLabelDisplay="on"
              />
            </Box>
          </Box>
        );
      case 'radio':
        return (
          <Box sx={{ py: 4 }}>
           <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={responses[currentQuestionData.id] || ''}
                onChange={(e) => handleRadioChange(e.target.value)}
              >
                <Box sx={{ display: 'grid', gap: 2 }}>
                  {currentQuestionData.options.map((option) => (
                    <Paper
                      key={option}
                      sx={{
                        p: 2,
                        border: '1px solid',
                        borderColor: responses[currentQuestionData.id] === option ? '#6B46FE' : 'rgba(255,255,255,0.1)',
                        borderRadius: 2,
                        background: 'rgba(255,255,255,0.05)',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          borderColor: '#6B46FE',
                          backgroundColor: 'rgba(0, 119, 181, 0.02)',
                        },
                      }}
                    >
                      <FormControlLabel
                        value={option}
                        control={<StyledRadio />}
                        label={currentQuestionData.id === 'rentalEscalation' ? `${option} %` : `${option} months`}
                        sx={{ m: 0, width: '100%' }}
                      />
                    </Paper>
                  ))}
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        );

      case 'dropdown':
        return (
          <Box sx={{ py: 4 }}>
            <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <FormControl fullWidth sx={{ 
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                color: '#fff',
                borderRadius: 2,
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                },
                '&:hover fieldset': {
                  borderColor: '#6B46FE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6B46FE',
                }
              },
              '& .MuiSelect-icon': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              '& .MuiMenuItem-root': {
                color: '#fff',
              }
            }}>
              <Select
                value={responses[currentQuestionData.id] || ''}
                onChange={(e) => handleDropdownChange(e.target.value)}
                sx={{
                  '& .MuiSelect-select': {
                    color: '#fff',
                  }
                }}
              >
                {currentQuestionData.options.map((option) => (
                  <MenuItem 
                    key={option} 
                    value={option}
                    sx={{
                      backgroundColor: '#0A0B1E',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(107, 70, 254, 0.2)',
                        '&:hover': {
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                        }
                      }
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );

      case 'text':
        return (
          <Box sx={{ py: 4 }}>
              <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
              {currentQuestionData.title}
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%', border: '2px solid #6B46FE', borderRadius: '16px'}}>
              <StyledTextField
                fullWidth
                value={responses[currentQuestionData.id] || ''}
                onChange={(e) => handleTextChange(e.target.value)}
              />
            </FormControl>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ background: '#0A0B1E', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Box sx={{
          mt: { xs: 4, sm: 8 },
          mb: 4,
          px: { xs: 2, sm: 0 },
          position: 'relative',
        }}>
          <StyledProgressBar variant="determinate" value={progress} sx={{ mb: 4 }} />

          <StyledPaper elevation={0}>
            {renderQuestion()}

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 4,
              borderTop: '1px solid rgba(255,255,255,0.1)'
            }}>
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
                sx={{
                  visibility: currentQuestion === 0 ? 'hidden' : 'visible',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }
                }}
              >
                Back
              </Button>

              <StyledButton
                onClick={handleNext}
                endIcon={<ArrowForwardIcon />}
                disabled={isNextDisabled()}
                sx={{
                  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #5235CC 0%, #CC3D71 100%)'
                  },
                  '&.Mui-disabled': {
                    background: 'rgba(255, 255, 255, 0.12)',
                    color: 'rgba(255, 255, 255, 0.3)'
                  }
                }}
              >
                {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
              </StyledButton>
            </Box>
          </StyledPaper>
        </Box>
      </Container>
    </Box>
  );
}

export default NegotiatorQuestions;
