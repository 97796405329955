import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Paper,
  Divider,
  Chip,
  Button,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Fade,
  Avatar
} from '@mui/material';
import {
  LocationOn,
  Business,
  Assignment,
  TrendingUp,
  CheckCircle,
  Warning,
  PlaylistAddCheck,
  Info,
  AccessTime,
  AttachMoney,
  Speed,
  Download,
  Checklist

} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import html2pdf from 'html2pdf.js';
import ReportBanner from '../assets/images/advisoraI-report-banner.png';
import ReportBanner2 from '../assets/images/negotiatoraI-report-banner.png';
import { useParams } from 'react-router-dom';
import { getReportData } from '../services/reportService';
import ReactMarkdown from 'react-markdown';

const StyledCard = styled(Card)(({ theme }) => ({
  background: '#0A0B1E',
  color: '#fff',
  border: '1px solid #a9a9a9',
  borderRadius: '16px',
  marginBottom: theme.spacing(3),
  '& .MuiCardContent-root': {
    padding: theme.spacing(4),
  }
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: '#0A0B1E',
  color: '#fff',
  border: '1px solid rgba(255,255,255,0.1)',
  borderRadius: '16px !important',
  marginBottom: theme.spacing(3),
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root': {
    padding: theme.spacing(2),
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: '#FF4D8D',
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: theme.spacing(2),
  }
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(2),
  fontWeight: 600
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '60vh',
  gap: theme.spacing(3)
}));

const PulsingDot = styled('div')(({ theme, delay = 0 }) => ({
  width: 12,
  height: 12,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  margin: '0 4px',
  animation: 'pulse 1.5s ease-in-out infinite',
  animationDelay: `${delay}s`,
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0.5,
    },
    '50%': {
      transform: 'scale(1.2)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(0.8)',
      opacity: 0.5,
    },
  },
}));

const LoadingAnimation = () => {
  const messages = [
    "Fetching the report data..",
    "Crunching the numbers...",
    "Preparing your report..."
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <LoadingContainer>
      <CircularProgress size={60} thickness={4} />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <PulsingDot delay={0} />
        <PulsingDot delay={0.2} />
        <PulsingDot delay={0.4} />
      </Box>
      <Fade in={true} timeout={1000}>
        <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
          {messages[messageIndex]}
        </Typography>
      </Fade>
    </LoadingContainer>
  );
};

const GaugeChart = ({ score }) => {
  const normalizedScore = Math.min(100, Math.max(0, score)); // Ensure score is between 0-100
  const color = score >= 75 ? '#4CAF50' : score >= 50 ? '#FFC107' : '#F44336';

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={100}
          size={120}
          thickness={4}
          sx={{ color: (theme) => theme.palette.grey[200] }}
        />
        <CircularProgress
          variant="determinate"
          value={normalizedScore}
          size={120}
          thickness={4}
          sx={{
            color: color,
            position: 'absolute',
            left: 0,
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" component="div" color={color} sx={{ fontWeight: 'bold' }}>
            {Math.round(normalizedScore)}
          </Typography>
          <Typography variant="caption" component="div" color="text.secondary">
            Score
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Report = () => {
  const { documentId } = useParams();
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedRecommendations, setExpandedRecommendations] = useState({});

  const reportRef = useRef(null);
  const sectionRefs = useRef({});

  console.log(report, "report")

  useEffect(() => {
    const fetchReportData = async () => {
      if (!documentId) {
        setError('No report ID provided');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await getReportData(documentId);

        if (response?.success && response?.data) {
          const reportData = response.data;
          console.log(reportData, "reportData")

          if (!reportData.response) {
            setError('No report data available. Please generate a report first.');
          } else {
            setReport(reportData);
          }
        } else {
          setError(response?.error || 'Failed to fetch report data');
        }
      } catch (err) {
        console.error('Error fetching report:', err);
        setError('An error occurred while fetching the report');
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [documentId]);

  // Show loading state
  if (loading) {
    return (
      <LoadingAnimation />
    );
  }

  // Show error state
  if (error || !report || !report.response) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#0A0B1E',
        color: '#fff'
      }}>
        <Typography variant="h6" sx={{ color: '#FF4D8D' }}>
          {error || 'No report data available. Please generate a report first.'}
        </Typography>
      </Box>
    );
  }

  const reportData = {
    ...report.response
  };
  console.log(reportData, "reportData")

  const processAnswers = (answers) => {
    if (!answers) return {};
    // Filter out reportType from the answers
    const { reportType, ...filteredAnswers } = answers;
    return Object.entries(filteredAnswers).reduce((acc, [key, value]) => {
      // Skip empty or null values
      if (value === null || value === undefined || value === '') {
        return acc;
      }
      // Format specific fields
      if (key === 'agreementTerm' || key === 'lockinPeriod' || key === 'noticePeriod' || key === 'securityDeposit') {
        acc[key] = `${value} months`;
      } else if (key === 'rentPerMonth') {
        acc[key] = `₹${value}`;
      } else if (key === 'rentalEscalation') {
        acc[key] = `${value}%`;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const safeAnswers = report.requirement || {};
  const processedAnswers = processAnswers(safeAnswers);

  const toggleRecommendation = (id) => {
    setExpandedRecommendations(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const renderRecommendation = (rec) => {
    const isExpanded = expandedRecommendations[rec.id];
    // const truncatedText = rec.recommendation.length > 100 ? rec.recommendation.substring(0, 100) + '...' : rec.recommendation;
    const truncatedText = "";

    return (
      <div>
        {isExpanded ? rec.recommendation : truncatedText}
        {rec.recommendation.length > 0 && (
          <span 
            style={{ color: '#0288d1', cursor: 'pointer', marginLeft: '4px' }}
            onClick={() => toggleRecommendation(rec.id)}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </span>
        )}
      </div>
    );
  };

  const handleDownload = async () => {
    try {
      const element = reportRef.current;
      if (!element) {
        console.error('Report element not found');
        return;
      }

      const opt = {
        filename: `GoFloaters ${report.reportType || 'Report'} for ${report.companyData?.companyName}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale:  1,
          useCORS: true,
          logging: true
        },
        jsPDF: { 
          unit: 'in', 
          format: 'a4', 
          orientation: 'portrait', 
        }
      };

      // Create instance of html2pdf
      const worker = html2pdf().set(opt).from(element);
      
      // Generate PDF
      await worker.save();
      
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <Box ref={reportRef} sx={{ background: '#0A0B1E', minHeight: '100vh', py: 4 }}>
      <Container>
        <Box sx={{ mb: 6 }}>
          <Box
            component="img"
            src={report.reportType === 'advisorai' ? ReportBanner : ReportBanner2}
            alt="Office Space Recommendations"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 4,
              mb: 4,
            }}
          />


          {report.reportType === 'advisorai' && (
            <Box>
              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Summary
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    {reportData.overallRecommendation?.summary}
                  </Typography>

                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Speed color="primary" sx={{ mr: 1 }} />
                          <Typography variant="subtitle1" color="primary.main">
                            Key Highlights
                          </Typography>
                        </Box>
                        {Array.isArray(reportData.overallRecommendation?.strongPoints) && (
                          <List dense disablePadding>
                            {reportData.overallRecommendation?.strongPoints.map((point, index) => (
                              <ListItem key={index} sx={{ pl: 0 }}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                  <CheckCircle fontSize="small" color="success" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={point.point}
                                  primaryTypographyProps={{ variant: 'body2' }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Box sx={{ mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Warning color="warning" sx={{ mr: 1 }} />
                          <Typography variant="subtitle1" color="warning.main">
                            Considerations
                          </Typography>
                        </Box>
                        {Array.isArray(reportData.overallRecommendation?.concernPoints) && (
                          <List dense disablePadding>
                            {reportData.overallRecommendation?.concernPoints.map((point, index) => (
                              <ListItem key={index} sx={{ pl: 0 }}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                  <Info fontSize="small" color="warning" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={point.point}
                                  primaryTypographyProps={{ variant: 'body2' }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <PlaylistAddCheck color="info" sx={{ mr: 1 }} />
                          <Typography variant="subtitle1" color="info.main">
                            Next Steps
                          </Typography>
                        </Box>
                        {Array.isArray(reportData.overallRecommendation?.actionItems) && (
                          <List dense disablePadding>
                            {reportData.overallRecommendation?.actionItems.map((item, index) => (
                              <ListItem key={index} sx={{ pl: 0 }}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                  <PlaylistAddCheck fontSize="small" color="info" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={item.action}
                                  primaryTypographyProps={{ variant: 'body2' }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    <Checklist fontSize="small" color="primary" /> Recommended Priorities
                  </GradientTypography>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        label={`First Priority : ${reportData.priorities?.firstPriority}`}
                        avatar={<Avatar>1</Avatar>}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        label={`Second Priority : ${reportData.priorities?.secondPriority}`}
                        avatar={<Avatar>2</Avatar>}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        label={`Third Priority : ${reportData.priorities?.thirdPriority}`}
                        avatar={<Avatar>3</Avatar>}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="body1" paragraph sx={{mt:2}}>
                    {reportData.priorities?.rationale}
                  </Typography>
                </CardContent>
              </StyledCard>
              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                  <Business fontSize="small" color="primary" /> Recommended Office Type - {reportData.typeOfOfficeRecommendation?.officeType}
                  </GradientTypography>
                  {Array.isArray(reportData.typeOfOfficeRecommendation?.recommendations) && (
                    <List dense disablePadding>
                      {reportData.typeOfOfficeRecommendation?.recommendations.map((rec, index) => (
                        <Box key={index} sx={{ mb: index === reportData.typeOfOfficeRecommendation.recommendations.length - 1 ? 0 : 2 }}>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              <ReactMarkdown
                                components={{
                                  li: ({ children }) => (
                                    <Typography component="li" variant="body1" 
                                    sx={{ 
                                      color: 'text.primary',
                                      lineHeight: 1.6,
                                      mt:1
                                    }}>
                                      {children}
                                    </Typography>
                                  ),
                                  p: ({ children }) => (
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        color: 'text.primary',
                                        lineHeight: 1.6
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  ),
                                  strong: ({ children }) => (
                                    <Typography 
                                      component="span" 
                                      sx={{ 
                                        fontWeight: 600,
                                        color: 'primary.main'
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  )
                                }}
                              >
                                {rec.rationale}
                              </ReactMarkdown>
                            }
                          />
                        </ListItem>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              renderRecommendation(rec)
                            }
                          />
                        </ListItem>
                        </Box>
                      ))}
                    </List>
                  )}
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                  <LocationOn fontSize="small" color="primary" />Recommended Location(s) - {reportData.locationRecommendation?.location}
                  </GradientTypography>
                  {Array.isArray(reportData.locationRecommendation?.recommendations) && (
                    <List dense disablePadding>
                      {reportData.locationRecommendation?.recommendations.map((rec, index) => (
                        <Box key={index} sx={{ mb: index === reportData.locationRecommendation.recommendations.length - 1 ? 0 : 2 }}>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              <ReactMarkdown
                                components={{
                                  li: ({ children }) => (
                                    <Typography component="li" variant="body1" 
                                    sx={{ 
                                      color: 'text.primary',
                                      lineHeight: 1.6,
                                      mt:1
                                    }}>
                                      {children}
                                    </Typography>
                                  ),
                                  p: ({ children }) => (
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        color: 'text.primary',
                                        lineHeight: 1.6
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  ),
                                  strong: ({ children }) => (
                                    <Typography 
                                      component="span" 
                                      sx={{ 
                                        fontWeight: 600,
                                        color: 'primary.main',
                                        mt:1
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  )
                                }}
                              >
                                {rec.rationale}
                              </ReactMarkdown>
                            }
                          />
                        </ListItem>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              renderRecommendation(rec)
                            }
                          />
                        </ListItem>
                        </Box>
                      ))}
                    </List>
                  )}
                </CardContent>
              </StyledCard>
              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                  <Info fontSize="small" color="primary" /> Important Amenities
                  </GradientTypography>
                  {/*  reportData.amenitiesRecommendation.amenities is a comma separated string. Show only the first 3 as a chip*/}
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    {/* Show only the first 3 amenities */}
                    {reportData.amenitiesRecommendation?.amenities && reportData.amenitiesRecommendation?.amenities.split(",").map((amenity, index) => index < 3 && (
                      <Grid item xs={6} sm={3} key={index}>
                        <Chip
                        // Capitalize first letter of every word
                          label={amenity.replace(/\b\w/g, (c) => c.toUpperCase())}
                          avatar={<Avatar>{index + 1}</Avatar>}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                  ))}
                  </Grid>
                  <Typography variant="body1" paragraph sx={{mt:2}}>
                    {reportData.amenitiesRecommendation?.rationale}
                  </Typography>
                  </CardContent>
                </StyledCard> 
              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                  <Assignment fontSize="small" color="primary" /> Agreement Terms
                  </GradientTypography>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        icon={<AccessTime  />}
                        label={`Duration: ${reportData.termsRecommendation?.agreementDuration}`}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        icon={<Assignment />}
                        label={`Lock-in: ${reportData.termsRecommendation?.lockInPeriod}`}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        icon={<Info />}
                        label={`Notice: ${reportData.termsRecommendation?.noticePeriod}`}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Chip
                        icon={<AttachMoney />}
                        label={`Escalation: ${reportData.termsRecommendation?.escalation}`}
                        sx={{
                          backgroundColor: 'rgba(107, 70, 254, 0.3)',
                          color: '#FFF',
                          borderRadius: '16px',
                          '& .MuiChip-label': {
                            px: 2,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  {Array.isArray(reportData.termsRecommendation?.recommendations) && (
                    <List dense disablePadding>
                      {reportData.termsRecommendation?.recommendations.map((rec, index) => (
                        <Box key={index} sx={{ mb: index === reportData.termsRecommendation.recommendations.length - 1 ? 0 : 2 }}>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              <ReactMarkdown
                                components={{
                                  li: ({ children }) => (
                                    <Typography component="li" variant="body1" 
                                    sx={{ 
                                      color: 'text.primary',
                                      lineHeight: 1.6,
                                      mt:1
                                    }}>
                                      {children}
                                    </Typography>
                                  ),
                                  p: ({ children }) => (
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        color: 'text.primary',
                                        lineHeight: 1.6
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  ),
                                  strong: ({ children }) => (
                                    <Typography 
                                      component="span" 
                                      sx={{ 
                                        fontWeight: 600,
                                        color: 'primary.main'
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  )
                                }}
                              >
                                {rec.rationale}
                              </ReactMarkdown>
                            }
                          />
                        </ListItem>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              renderRecommendation(rec)
                            }
                          />
                        </ListItem>
                        </Box>
                      ))}
                    </List>
                  )}
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                  <TrendingUp fontSize="small" color="primary" /> Market Insights
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    {reportData.marketInsights?.trends}
                  </Typography>
                  {Array.isArray(reportData.marketInsights?.recommendations) && (
                    <List dense disablePadding>
                      {reportData.marketInsights?.recommendations.map((rec, index) => (
                        <Box key={index} sx={{ mb: index === reportData.marketInsights.recommendations.length - 1 ? 0 : 2 }}>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              <ReactMarkdown
                                components={{
                                  li: ({ children }) => (
                                    <Typography component="li" variant="body1" 
                                    sx={{ 
                                      color: 'text.primary',
                                      lineHeight: 1.6,
                                      mt:1
                                    }}>
                                      {children}
                                    </Typography>
                                  ),
                                  p: ({ children }) => (
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        color: 'text.primary',
                                        lineHeight: 1.6
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  ),
                                  strong: ({ children }) => (
                                    <Typography 
                                      component="span" 
                                      sx={{ 
                                        fontWeight: 600,
                                        color: 'primary.main'
                                      }}
                                    >
                                      {children}
                                    </Typography>
                                  )
                                }}
                              >
                                {rec.rationale}
                              </ReactMarkdown>
                            }
                          />
                        </ListItem>
                        <ListItem sx={{ 
                          pl: 0,
                          borderRadius: 1,
                          mb: 0.5,
                        }}>
                          <ListItemText
                            primary={
                              renderRecommendation(rec)
                            }
                          />
                        </ListItem>
                        </Box>
                      ))}
                    </List>
                  )}
                </CardContent>
              </StyledCard>
            </Box>
          )}

          {report.reportType === 'negotiatorai' && (
            <Box>
              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Space Analysis
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Capacity:</Typography> {reportData.spaceAnalysis.capacity || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Layout:</Typography> {reportData.spaceAnalysis.layout || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Location:</Typography> {reportData.spaceAnalysis.location || 'Not available'}
                  </Typography>
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Financial Analysis
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Rent Analysis:</Typography> {reportData.financialAnalysis.rentAnalysis || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Deposit Structure:</Typography> {reportData.financialAnalysis.depositStructure || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Cost Efficiency:</Typography> {reportData.financialAnalysis.costEfficiency || 'Not available'}
                  </Typography>
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Terms Analysis
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Agreement Duration:</Typography> {reportData.termsAnalysis.agreementDuration || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Lock-in Period:</Typography> {reportData.termsAnalysis.lockInPeriod || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Notice Period:</Typography> {reportData.termsAnalysis.noticePeriod || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Escalation:</Typography> {reportData.termsAnalysis.escalation || 'Not available'}
                  </Typography>
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Market Insights
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Market Rate:</Typography> {reportData.marketInsights.marketRate || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Trends:</Typography> {reportData.marketInsights.trends || 'Not available'}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Competitive Position:</Typography> {reportData.marketInsights.competitivePosition || 'Not available'}
                  </Typography>
                </CardContent>
              </StyledCard>

              <StyledCard>
                <CardContent>
                  <GradientTypography variant="h5">
                    Overall Recommendation
                  </GradientTypography>
                  <Typography variant="body1" paragraph>
                    <Typography color='primary.black'>Summary:</Typography> {reportData.overallRecommendation.summary || 'Not available'}
                  </Typography>
                  {reportData.overallRecommendation.concernPoints && reportData.overallRecommendation.concernPoints.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1">Concern Points:</Typography>
                      {reportData.overallRecommendation.concernPoints.map((concern, index) => (
                        <Typography key={index} sx={{ mb: 1 }}>
                          • {concern.point}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </Box>
          )}
                    <StyledAccordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  color: '#fff',
                }
              }}
            >
              <GradientTypography variant="h5">
                Your Inputs
              </GradientTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle1" color="primary.main" gutterBottom>
                    Company Details
                  </Typography>
                  {[
                    { label: 'Company Name', value: report.companyData?.companyName },
                    { label: 'Company Type', value: report.companyData?.companyType },
                    { label: 'City', value: report.companyData?.city },
                    { label: 'Team Size', value: report.companyData?.teamSize }
                  ].map(({ label, value }) => value && (
                    <Box key={label} sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" sx={{ color: '#666', mb: 0.5 }}>
                        {label}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#fff' }}>
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle1" color="primary.main" gutterBottom>
                    Requirements
                  </Typography>
                  {Object.entries(processedAnswers || {}).map(([question, answer]) => {
                    const labelMap = {
                      Privacy: 'Privacy',
                      Networking: 'Networking',
                      ClientVisits: 'Client Visits',
                      GrowthRate: 'Your Growth Rate',
                      PastWorkModel: 'Past Work Model',
                      CurrentWorkModel: 'Current Work Model',
                      FundingStatus: 'Funding Status',
                      OfficeType: 'Office Type',
                      City: 'City',
                      PropertyType: 'Property Type',
                      BillableSeats: 'Billable Seats',
                      RentalEscalation: 'Rental Escalation',
                      RentPerMonth: 'Rent Per Month',
                      AgreementTerm: 'Agreement Term',
                      LockinPeriod: 'Lock-in Period',
                      NoticePeriod: 'Notice Period',
                      SecurityDeposit: 'Security Deposit',
                    };

                    const label = labelMap[question] || question.charAt(0).toUpperCase() + question.slice(1);
                    console.log(label, answer)
                    if (question === 'reportType') return null;
                    if (question === 'propertyName') return null;

                    return (
                      <Box key={question} sx={{ mt: 2 }}>
                        <Typography variant="subtitle2"  sx={{ color: '#666', mb: 0.5, font:300}}>
                          {label}
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#fff' }}>
                          {Array.isArray(answer) ? answer.join(', ') : (answer || 'Not specified')}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      </Container>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{
            background: 'linear-gradient(45deg, #6B46FE 0%, #FF4D8D 100%)',
            color: '#fff',
            borderRadius: '30px',
            padding: '12px 32px',
            '&:hover': {
              background: 'linear-gradient(45deg, #5835E5, #E53D7A)',
            }
          }}
        >
          Download Report
        </Button>
      </Box>
    </Box>
  );
};

export default Report;
